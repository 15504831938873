<template>
  <div class="map-settings" :class="{ open: isOpen }">
    <div class="header" @click="toggleSettings">
      <span>Settings</span>
      <span class="icon"><i :class="['fa', isOpen ? 'fa-arrow-up' : 'fa-arrow-down']"></i></span>
    </div>
    <div class="container">
      <section>
        <h3>Equipment</h3>
        <div class="items">
          <span
            class="item"
            v-for="item of equipmentList"
            :class="{ selected: selectedEquipments.includes(item.id) }"
            @click="toggleEquipment(item)"
            >{{ item.name }}</span
          >
        </div>
      </section>

      <section>
        <h3>Type</h3>
        <div class="items">
          <span
            class="item"
            v-for="item of vehicleTypeList"
            :class="{ selected: selectedVehicleTypes.includes(item.id) }"
            @click="toggleVehicleType(item)"
            >{{ item.name }}</span
          >
        </div>
      </section>

      <section>
        <h3>Resident status</h3>
        <div class="items">
          <span
            class="item"
            v-for="item in ResidentStatus"
            :class="{ selected: selectedResidentStatuses.includes(item) }"
            @click="toggleResidentStatus(item)"
            >{{ getStatusTranslate(item) }}</span
          >
        </div>
      </section>

      <div class="form-check mb-4">
        <label class="form-check-label" for="save-search-history">Save search history</label>
        <input
          class="form-check-input"
          type="checkbox"
          id="save-search-history"
          v-model="saveSearchHistory"
          @change="toggleSaveSearchHistory"
        />
      </div>

      <button class="reset" @click="reset">Reset</button>
    </div>
  </div>
</template>

<script>
import { getEquipmentList, getVehicleTypeList } from '@/api';
import { ResidentStatus } from '@/types/resident-status';
import { personService } from '@/modules/person/person.service';

export default {
  name: 'MapSettings',
  data: () => ({
    isOpen: false,

    equipmentList: [],
    selectedEquipments: [],

    vehicleTypeList: [],
    selectedVehicleTypes: [],

    selectedResidentStatuses: [],

    saveSearchHistory: false,

    ResidentStatus,
  }),
  methods: {
    getStatusTranslate(status) {
      return personService.getStatusTranslate(status);
    },

    saveSettingsOpenState() {
      localStorage.setItem('map-settings-open', this.isOpen);
    },

    loadSettingsOpenState() {
      this.isOpen = JSON.parse(localStorage.getItem('map-settings-open')) || false;
    },

    toggleSettings() {
      this.isOpen = !this.isOpen;
      this.saveSettingsOpenState();
    },

    // #region Equipment
    saveSelectedEquipment() {
      localStorage.setItem('map-settings-selected-equipments', JSON.stringify(this.selectedEquipments));
    },

    loadSelectedEquipment() {
      try {
        this.selectedEquipments = JSON.parse(localStorage.getItem('map-settings-selected-equipments')) || [];
      } catch (err) {
        this.selectedEquipments = [];
      }

      this.fireEvent();
    },

    resetSelectedEquipment() {
      this.selectedEquipments = [];
      this.saveSelectedEquipment();
      this.fireEvent();
    },

    toggleEquipment(equipment) {
      if (this.selectedEquipments.includes(equipment.id)) {
        this.selectedEquipments = this.selectedEquipments.filter(id => id !== equipment.id);
      } else {
        this.selectedEquipments.push(equipment.id);
      }

      this.saveSelectedEquipment();
      this.fireEvent();
    },

    fetchEquipmentList() {
      getEquipmentList().then(response => {
        if (!response.data) {
          return;
        }

        this.equipmentList = response.data.equipments;
      });
    },
    // #endregion Equipment

    // #region VehicleType
    saveSelectedVehicleType() {
      localStorage.setItem('map-settings-selected-vehicle-type', JSON.stringify(this.selectedVehicleTypes));
    },

    loadSelectedVehicleType() {
      try {
        this.selectedVehicleTypes = JSON.parse(localStorage.getItem('map-settings-selected-vehicle-type')) || [];
      } catch (err) {
        this.selectedVehicleTypes = [];
      }

      this.fireEvent();
    },

    resetSelectedVehicleType() {
      this.selectedVehicleTypes = [];
      this.saveSelectedVehicleType();
      this.fireEvent();
    },

    toggleVehicleType(vehicleType) {
      if (this.selectedVehicleTypes.includes(vehicleType.id)) {
        this.selectedVehicleTypes = this.selectedVehicleTypes.filter(id => id !== vehicleType.id);
      } else {
        this.selectedVehicleTypes.push(vehicleType.id);
      }

      this.saveSelectedVehicleType();
      this.fireEvent();
    },

    fetchVehicleTypeList() {
      getVehicleTypeList().then(response => {
        if (!response.data) {
          return;
        }

        this.vehicleTypeList = response.data.vehicleTypes;
      });
    },
    // #endregion VehicleType

    // #region ResidentStatus
    saveSelectedResidentStatus() {
      localStorage.setItem('map-settings-selected-resident-status', JSON.stringify(this.selectedResidentStatuses));
    },

    loadSelectedResidentStatus() {
      try {
        this.selectedResidentStatuses = JSON.parse(localStorage.getItem('map-settings-selected-resident-status')) || [];
      } catch (err) {
        this.selectedResidentStatuses = [];
      }

      this.fireEvent();
    },

    resetSelectedResidentStatus() {
      this.selectedResidentStatuses = [];
      this.saveSelectedResidentStatus();
      this.fireEvent();
    },

    toggleResidentStatus(status) {
      if (this.selectedResidentStatuses.includes(status)) {
        this.selectedResidentStatuses = this.selectedResidentStatuses.filter(i => i !== status);
      } else {
        this.selectedResidentStatuses.push(status);
      }

      this.saveSelectedResidentStatus();
      this.fireEvent();
    },
    // #endregion ResidentStatus

    // #region Save search history
    saveSaveSearchHistory() {
      localStorage.setItem('map-settings-save-search-history', JSON.stringify(this.saveSearchHistory));
    },

    loadSaveSearchHistory() {
      try {
        this.saveSearchHistory = JSON.parse(localStorage.getItem('map-settings-save-search-history')) ?? true;
      } catch (err) {
        this.saveSearchHistory = true;
      }

      this.fireEvent();
    },

    toggleSaveSearchHistory() {
      this.saveSaveSearchHistory();
      this.fireEvent();
    },
    // #endregion Save search history

    reset() {
      this.resetSelectedEquipment();
      this.resetSelectedVehicleType();
      this.resetSelectedResidentStatus();
    },

    fireEvent() {
      this.$emit('settings-changed', {
        selectedEquipments: this.selectedEquipments,
        selectedVehicleTypes: this.selectedVehicleTypes,
        selectedResidentStatuses: this.selectedResidentStatuses,
        saveSearchHistory: this.saveSearchHistory,
      });
    },
  },
  created() {
    this.loadSettingsOpenState();
    this.loadSelectedEquipment();
    this.loadSelectedVehicleType();
    this.loadSelectedResidentStatus();
    this.loadSaveSearchHistory();
  },
  watch: {
    isOpen(value) {
      if (value) {
        this.loadSelectedEquipment();
        this.fetchEquipmentList();

        this.loadSelectedVehicleType();
        this.fetchVehicleTypeList();

        this.loadSelectedResidentStatus();

        this.loadSaveSearchHistory();
      }
    },
  },
};
</script>

<style scoped>
[data-theme='default'] .map-settings {
  --text-color: #2f302e;
  --primary-color: #a8aad8;
  --background-color: #ffffff;

  --border-color: #2f302e;

  --header-text-color: var(--text-color);
  --header-background-color: var(--primary-color);
  --header-open-text-color: var(--text-color);
  --header-open-background-color: var(--background-color);

  --item-color: #b3b3b3;
  --item-selected-background-color: var(--primary-color);
  --item-selected-text-color: var(--text-color);

  --reset-button-background-color: #699278;
  --reset-button-color: #ffffff;
  --reset-button-shadow-color: #2f302e;
}

[data-theme='dark'] .map-settings {
  --text-color: #bfcfd2;
  --primary-color: #a8aad8;
  --background-color: #112025;

  --border-color: #1d3c47;

  --header-text-color: #112025;
  --header-background-color: #64b3aa;
  --header-open-text-color: var(--text-color);
  --header-open-background-color: var(--background-color);

  --item-color: #b3b3b3;
  --item-selected-background-color: var(--primary-color);
  --item-selected-text-color: #112025;

  --reset-button-background-color: #699278;
  --reset-button-color: #112025;
  --reset-button-shadow-color: #297d73;
}

.map-settings {
  position: relative;
  height: 3.2rem;
  color: var(--text-color);
  background: var(--background-color);
  border-radius: 0.6rem;
  transition: all 0.3s;
}

.open {
  height: fit-content;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.header {
  width: 100%;
  height: 3.2rem;

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background: var(--header-background-color);
  border: solid 0.16rem var(--border-color);
  border-radius: 0.6rem;
  font-size: 1.2rem;
  color: var(--header-text-color);
  transition: background-color 0.3s;
  cursor: pointer;
}

.open .header {
  color: var(--header-open-text-color);
  background: var(--header-open-background-color);
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.header span {
  padding: 0.6rem 1.2rem;
}

.icon {
  padding: 0.6rem;
}

.container {
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  height: 0;

  max-height: 85vh;

  overflow: hidden;
  background: var(--background-color);
  border-bottom-left-radius: 0.6rem;
  border-bottom-right-radius: 0.6rem;

  transition: height 0.3s;
}

.open .container {
  padding: 1rem;
  height: fit-content;
  overflow-y: auto;
  border: solid 0.16rem var(--border-color);
  border-top: transparent;
}

section {
  margin-bottom: 1.6rem;
}

section:last-child {
  margin-bottom: 0;
}

section h3 {
  font-size: 1.4rem;
  font-weight: bold;
  margin-bottom: 0.8rem;
}

.items {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.item {
  font-size: 1rem;
  color: var(--item-color);
  padding: 0.4rem 0.6rem;
  border: solid 0.08rem var(--item-color);
  border-radius: 1.2rem;
  margin: 0 0.4rem 0.4rem 0;
  cursor: pointer;
}

.item.selected {
  background: var(--item-selected-background-color);
  color: var(--item-selected-text-color);
}

.reset {
  color: var(--reset-button-color);
  background: var(--reset-button-background-color);
  font-family: 'Refinery 95 Bold';
  font-size: 1.4rem;
  font-weight: 400;
  line-height: 2.2rem;
  border-radius: 0.6rem;
  padding: 0.24rem 1.4rem 0.48rem;
  box-shadow: 0.06rem 0.24rem 0 0 var(--reset-button-shadow-color);
}
</style>
